import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import tw, { styled } from 'twin.macro'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import { useStateContext } from '@context/stateContext'
import { strings } from '../../static/strings'
import { useInView } from 'react-intersection-observer'

const FooterSection = tw.footer`pt-11`
const FooterWrapper = tw.div`flex flex-wrap items-start justify-center flex-col px-5 md:px-0 sm:flex-row max-w-[1440px] mx-auto `
const FooterItem = tw.div`flex flex-col flex-wrap items-start justify-start px-5 pb-11 w-full sm:w-auto lg:mb-0 lg:px-7 xl:px-10 2xl:px-16 max-w-[246px] box-content`
const FooterItemHeadline = tw.p`pt-3 text-sm leading-5 text-mediumLight font-futuraBook`
const FooterLogo = tw(GatsbyImage)`object-contain h-auto w-[125px] mb-5`
const FooterSocialWrapper = tw.div`flex justify-start items-center mt-1 max-w-[246px] flex-wrap`
const FooterSocialIcon = tw(GatsbyImage)`object-contain h-auto w-[28px] mr-1.5 mt-1.5 rounded box-border`
const FooterLinkBase = tw`pt-3 text-sm leading-5 text-black uppercase cursor-pointer`
const FooterLink = styled(Link)`
	${FooterLinkBase}
`
const FooterExternalLink = styled.a`
	${FooterLinkBase}
`

const LogosSection = tw.section`pt-24 md:pt-40 pb-11`
const LogosWrapper = tw.div`flex flex-wrap items-center justify-around px-5 mx-auto lg:px-16 max-w-[1700px]`
const LogosImage = tw(GatsbyImage)`object-contain w-auto max-w-[85px] xs:max-w-[70px] md:max-w-[85px]  xl:max-w-[100px] 2xl:max-w-[122px]`
const LogosLink = tw.a`flex justify-center w-[26%] xs:w-[20%] md:w-[12%] mb-4 px-2 xl:px-6`

const PartnerLogos = ({ logos }) => {
	return logos.map((item, index) => (
		<LogosLink className={item.link === null && 'pointer-events-none'} key={index} href={item.link?.url} target={item.link?.target}>
			<LogosImage image={getImage(item.image?.localFile)} alt={item.image?.altText} />
		</LogosLink>
	))
}

const Logo = ({ footerLogo }) => {
	if (!footerLogo) return <StaticImage className='object-contain mb-5' imgClassName='object-contain h-auto w-[125px]' src='../../images/logo.webp' alt='' loading='eager' placeholder='none' />
	return <FooterLogo image={getImage(footerLogo?.localFile)} alt={footerLogo?.altText} />
}
const SocialLink = ({ title, url, icon }) => {
	return (
		<a title={title} href={url} target='_blank' rel='noreferrer'>
			<FooterSocialIcon image={getImage(icon?.localFile)} alt={icon?.altText} />
		</a>
	)
}

function isValidURL(string) {
	const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
	return res !== null
}

const getLink = (label, href) => {
	if (isValidURL(href)) {
		return (
			<FooterExternalLink href={href ?? '/'} target='_blank' key={`External` + href + label}>
				{label}
			</FooterExternalLink>
		)
	} else {
		return (
			<FooterLink to={href ?? '/'} key={`Internal` + href + label}>
				{label}
			</FooterLink>
		)
	}
}

const Links = ({ node }) => {
	if (node.menuItems.length === 0) {
		return null
	}
	return node.menuItems.nodes.map((node, index) => {
		return getLink(node.label, node.path)
	})
}

const Menus = ({ footerData, footerLinks }) => {
	const { language } = useStateContext()

	const getString = (string) => {
		const result = Object.keys(strings['de']).find((key) => strings['de'][key] === string)
		if (language === 'en') {
			return strings[language][result]
		}
		return string
	}

	return footerData.footerMenus.map((item, index) => {
		let localeRegisteredMenus = item.registeredMenus
		if (language === 'en') {
			localeRegisteredMenus = item.registeredMenus + '-eng'
		}
		if (index === 0) {
			return (
				<FooterItem key={`FooterItem` + index}>
					<Logo footerLogo={footerData.footerLogo} />
					{footerLinks.map((link, index) => {
						if (localeRegisteredMenus === link.node.slug) {
							return <Links node={link.node} key={`MapLinks` + index + link.node.slug} />
						}
						return null
					})}
				</FooterItem>
			)
		}
		if (index === 1) {
			return (
				<FooterItem key={`FooterItem` + index}>
					<FooterItemHeadline>{getString(item.footerTitle)}</FooterItemHeadline>
					{footerLinks.map((link, index) => {
						if (localeRegisteredMenus === link.node.slug) {
							return <Links node={link.node} key={`MapLinks` + index + link.node.slug} />
						}
						return null
					})}
					<FooterItemHeadline tw='pt-6'>{footerData.socialsTitle}</FooterItemHeadline>
					<FooterSocialWrapper>
						{footerData.socials.map((link, index) => {
							return <SocialLink title={link.title} url={link.url} icon={link.icon} key={`SocialLink` + index + link.title} />
						})}
					</FooterSocialWrapper>
				</FooterItem>
			)
		}
		if (!!item.information) {
			const filterInformationBlock = (block) => {
				if (language === 'en') {
					let parsed
					parsed = block.replace('Rechtliche Hinweise', 'Legal notes')
					parsed = parsed.replace('Datenschutz', 'Data protection')
					parsed = parsed.replace('Impressum', 'Imprint')
					parsed = parsed.replaceAll('href="', 'href="/en')
					return parse(parsed)
				}
				return parse(block)
			}
			return (
				<FooterItem key={`FooterItem` + index}>
					<FooterItemHeadline>{getString(item.footerTitle)}</FooterItemHeadline>
					<p tw='pt-3'> {filterInformationBlock(item.information)}</p>
				</FooterItem>
			)
		}
		return (
			<FooterItem key={`FooterItem` + index}>
				<FooterItemHeadline>{getString(item.footerTitle)}</FooterItemHeadline>
				{footerLinks.map((link, index) => {
					if (localeRegisteredMenus === link.node.slug) {
						return <Links node={link.node} key={`MapLinks` + index + link.node.slug} />
					}
					return null
				})}
			</FooterItem>
		)
	})
}

function Footer() {
	const data = useStaticQuery(graphql`
		query FooterMenu {
			allFooterMenus: allWpMenu {
				edges {
					node {
						name
						slug
						menuItems {
							nodes {
								path
								label
								id
								parentId
								url
							}
						}
					}
				}
			}
			footerOptions: wp {
				acfOptionsFooter {
					themeOptionsFooter {
						footerMenus {
							registeredMenus
							information
							footerTitle
						}
						socialsTitle
						socials {
							icon {
								localFile {
									childImageSharp {
										gatsbyImageData
									}
								}
								altText
							}
							url
							title
						}
						logos {
							image {
								localFile {
									childImageSharp {
										gatsbyImageData(width: 150, quality: 75, formats: [AUTO, WEBP], placeholder: TRACED_SVG)
									}
								}
								altText
							}
							link {
								target
								title
								url
							}
						}
					}
				}
			}
		}
	`)

	const { allFooterMenus, footerOptions } = data
	const footerData = footerOptions.acfOptionsFooter.themeOptionsFooter
	const footerLinks = allFooterMenus.edges
	const menus = footerOptions.acfOptionsFooter.themeOptionsFooter.footerMenus
	const logos = footerOptions.acfOptionsFooter.themeOptionsFooter.logos

	const [ref, isVisible] = useInView({ threshold: 0, triggerOnce: true })
	return (
		<>
			<LogosSection ref={ref}>{isVisible && <LogosWrapper>{logos?.length > 0 && <PartnerLogos logos={logos} />}</LogosWrapper>}</LogosSection>
			<FooterSection>
				<FooterWrapper>{menus?.length > 0 && <Menus footerData={footerData} footerLinks={footerLinks} />}</FooterWrapper>
			</FooterSection>
		</>
	)
}

export default Footer
